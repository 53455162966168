/* eslint-disable max-len */
import _ from 'lodash'

import React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { device } from '../../../theme/device'
import {
  Wrapper,
  P,
  H1,
  Subtitle,
  Video,
} from '../../../components'
import BlockInfo from './block-info'

const WrapperContainerBlock = styled((props) => <Wrapper {...props} />)`

  @media ${device.tablet} {
    margin-bottom: 0;
  }
  
`

const WrapperExtended = styled((props) => <Wrapper {...props} />)`

  @media ${device.tablet} { 
    margin: 40px 0 80px;
  }

`

const Block = ({
  type,
  info,
  companyName,
  id,
  t,
}) => {
  const to = _.lowerCase(_.get(info, 'duration.service_to')) === 'present' ? t('offer.present') : _.get(info, 'duration.service_to')
  const fromTo = `${_.get(info, 'duration.service_from')} - ${to}`
  const marketList = !_.isEmpty(_.get(info, 'market_list')) ? _.map(info.market_list).join(', ') : []

  return (
    <Wrapper
      id={id}
      widthD="1920px"
      marginD={type === 'Cargo' ? '0 0 100px 0' : '0'}
      alignItemsM="flex-start"
      flexDirectionD="column"
    >
      <WrapperContainerBlock
        marginM="0 0 80px"
        flexDirectionD="column"
        widthD="1240px"
      >
        <Wrapper
          paddingD="0 15px"
          marginD="0 0 120px 0"
          justifyContentD="flex-start"
          alignItemsD="flex-start">
          <Wrapper
            flexDirectionD="column"
            justifyContentM="flex-start"
            alignItemsM="flex-start"
            marginD="0 245px 0 0"
            paddingM="0 25px"
          >
            <Wrapper
              alignItemsM="flex-start"
              justifyContentM="flex-start"
              marginD="0 0 32px 0"
              flexDirectionM="row"
              style={{ flexWrap: 'wrap' }}

            >
              <H1 color="primary">{companyName}</H1>
              <H1><span style={{ margin: '0 10px' }}>/</span>{type}</H1>
            </Wrapper>
            <P
              fontSizeD="2xl"
              lineHeight="5xl"
              lineHeightD="5xl"
              fontSizeM="lg"
              font="manrope"
              fontWeightM="light"
              fontWeightD="light"
            >{_.get(info, 'description')}</P>
          </Wrapper>
          <WrapperExtended
            flexDirectionD="column"
            justifyContentM="flex-start"
            alignItemsM="flex-start"
            marginM="40px 0 0px"
            paddingM="0 25px"
          >
            <Subtitle style={{ marginBottom: '8px' }}>{t('server.duration')}</Subtitle>
            <P
              fontSizeD="2xl"
              fontSizeM="2xl"
              lineHeight="5xl"
              lineHeightD="5xl"
              font="manrope"
              fontWeightM="light"
              style={{ marginBottom: '39px' }}>{fromTo}</P>
            <Subtitle style={{ marginBottom: '8px' }}>{t('server.market')}</Subtitle>
            <P
              fontSizeD="2xl"
              fontSizeM="2xl"
              lineHeight="5xl"
              lineHeightD="5xl"
              font="manrope"
              fontWeightM="light">{marketList}</P>
          </WrapperExtended>

        </Wrapper>
        {
          _.get(info, 'video_link.url') && (
            <Wrapper
              heightD="100%"
              marginD="0 0 120px"
              heightM="210px">
              <Video videoInfo={_.get(info, 'video_link')}/>
            </Wrapper>
          )
        }

      </WrapperContainerBlock>
      <BlockInfo
        info={info}
        t={t}/>
    </Wrapper>

  )
}

export default withTranslation()(Block)
