import _ from 'lodash'
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react'
import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { device } from '../../theme/device'
import {
  Wrapper, Seo, ButtonMore, Spinner,
} from '../../components'
import Block from './components/block-type'
import StickyNavbar from './components/sticky-navbar'
import { makeRequest, changelanguages } from '../../helpers'
import './our-clients-detail.scss'

const Prev = styled.p`
  color: #E20613;
  font-family: Manrope;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
`

const WorkPrev = styled.p`
  color: #7B7B7B;
  font-family: Manrope;
  font-size: 34px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 48px;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;

  @media ${device.desktop} {
    text-decoration: none;
    &:hover {
      color: #E20613;
      text-decoration: underline;
    }

  }

`

const CompanyDetail = ({ params }) => {
  const { slug } = params
  const [partner, setPartner] = useState({})
  const [cargoIsActive, setCargoIsActive] = useState(false)
  const [passangerIsActive, setPassangerIsActive] = useState(false)
  const [offset, setOffset] = useState(0)
  const boxRefOne = useRef(null)
  const boxRefTwo = useRef(null)
  const [scrolled, setScrolled] = React.useState(false)
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const breakpoints = useBreakpoint()
  const hasCargo = _.includes(_.get(partner, 'services'), 'cargo')
  const hasPassenger = _.includes(_.get(partner, 'services'), 'passenger')

  const getPartner = useCallback(async () => {
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'partners',
      params: {
        lang: changelanguages(i18n.language),
        ...params,
      },
    }).then((resp) => {
      const data = resp.data[0]
      setPartner(data)
      setLoading(false)
    })
  }, [slug])

  const handleScroll = useCallback(() => {
    if (offset > 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }, [offset])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
  }, [])

  useEffect(() => {
    setScrolled(false)
  }, [slug])

  useEffect(() => {
    if (typeof window !== 'undefined' && !loading) {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  })

  useEffect(() => {
    const block = boxRefOne.current

    if (block) {
      if (block.offsetTop - 50 <= offset && block.offsetTop + block.offsetHeight > offset + 150) {
        setCargoIsActive(true)
      } else {
        setCargoIsActive(false)
      }
    }
  }, [offset])

  useEffect(() => {
    const block = boxRefTwo.current

    if (block) {
      if (block.offsetTop - 150 <= offset && block.offsetTop + block.offsetHeight > offset) {
        setPassangerIsActive(true)
      } else {
        setPassangerIsActive(false)
      }
    }
  }, [offset])

  useEffect(() => {
    getPartner()
  }, [slug, i18n.language])

  if (loading) {
    return <Spinner loading={loading} />
  }

  const useScrollToHash = (hash) => {
    if (!hash && typeof window !== 'undefined') return

    window.requestAnimationFrame(() => {
      const anchor = document.querySelector(hash)
      const offsetY = anchor.getBoundingClientRect().top + window.scrollY
      window.scroll({ top: offsetY - 150, left: 0 })
    })
  }

  return (
    <React.Fragment>
      <Seo
        title={_.get(partner, 'yoast_head_json.title')}
        description={_.get(partner, 'yoast_head_json.description')}
        schema={_.get(partner, 'yoast_head_json.schema')}
        type={_.get(partner, 'yoast_head_json.og_type', {})}
      />
      <Wrapper flexDirectionD="column">
        {((hasCargo && hasPassenger) && scrolled)
          && <StickyNavbar
            cargoIsActive={cargoIsActive}
            passangerIsActive={passangerIsActive}
            scrolled={scrolled}
            t={t}
            scrolledTo={useScrollToHash}/>
        }
        <Wrapper
          flexDirectionD="column"
          displayM="box"
          paddingD="0">
          <Wrapper
            widthD="1920px"
            flexDirectionD="column"
          >
            <Wrapper
              widthD="1240px"
              paddingD="0 15px 0 76px"
              justifyContentD="flex-start"
              alignItemsM="flex-start"
              paddingM="0 0 0 75px"
              marginD="63px 0"
              heightM="120px"
              heightD="75px">
              <ButtonMore
                link="/our-clients"
              >{t('server.buttons.back')}
              </ButtonMore>
            </Wrapper>
            <div ref={boxRefOne}>
              {
                hasCargo && (

                  <Block
                    t={t}
                    id="cargo"
                    type={t('server.buttons.cargo')}
                    info={_.get(partner, 'acf.cargo')}
                    companyName={_.get(partner, 'title.rendered')}
                  />
                )
              }
            </div>
            <div ref={boxRefTwo}>
              {
                hasPassenger && (
                  <Block
                    t={t}
                    id="passenger"
                    type={t('server.buttons.passenger')}
                    info={_.get(partner, 'acf.passenger')}
                    companyName={_.get(partner, 'title.rendered')}
                  />

                )
              }
            </div>
          </Wrapper>
        </Wrapper>
        <Wrapper
          widthD="1240px"
          justifyContentD="space-around"
          marginD="36px 0"
          marginM="0 0 120px"
          paddingM="0 25px"
        >
          {
            !breakpoints.xs && _.get(partner, 'prev.slug') && (
              <Wrapper
                marginM="0 0 64px 0"
                marginD="0 0 64px 0"
                flexDirectionD="column"
                justifyContentD="flex-start"
                alignItemsD="flex-start"
              >
                <Prev>{t('server.buttons.pre')}</Prev>
                <Link to={`/our-clients/${_.get(partner, 'prev.slug')}/`}>
                  <WorkPrev>
                    {_.get(partner, 'prev.title')}
                  </WorkPrev>
                </Link>
              </Wrapper>
            )
          }

          {_.get(partner, 'next.slug') && <Wrapper
            flexDirectionD="column"
            justifyContentD="flex-start"
            alignItemsD="flex-end">
            <Prev>{t('server.buttons.next')}</Prev>
            <Link to={`/our-clients/${_.get(partner, 'next.slug')}/`}>
              <WorkPrev>
                {_.get(partner, 'next.title')}
              </WorkPrev>
            </Link>
          </Wrapper>}

        </Wrapper>
      </Wrapper>
    </React.Fragment>

  )
}

export default CompanyDetail
